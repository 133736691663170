import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Constants from '../configs/constants';

import axios from 'axios';

export const getQuotes = createAsyncThunk(
  'quotes/list',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios.post(Constants.API_URL + 'Quotes/getQuotesList', userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const submitQuote = createAsyncThunk(
  'quote/submit',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios.post(Constants.API_URL + 'Quotes/submitQuote', userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const declineQuote = createAsyncThunk(
  'quote/decline',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios.post(Constants.API_URL + 'Quotes/declineQuote', userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const manualQuoteSubmit = createAsyncThunk(
  'quote/manual',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios.post(Constants.API_URL + 'Quotes/submitManualQuote', userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const quotesSlice = createSlice({
  name: 'quotes',
  initialState: {
    isQuotesFetching: false,
    isQuotesSuccess: false,
    isQuotesError: false,
    quotesErrorMessage: '',
    quotesList: [],
    isSubmitQuoteFetching: false,
    isSubmitQuoteSuccess: false,
    isSubmitQuoteError: false,
    submitQuoteErrorMessage: '',
    isDeclineQuoteFetching: false,
    isDeclineQuoteSuccess: false,
    isDeclineQuoteError: false,
    declineQuoteErrorMessage: '',
    isManualQuoteSubmitFetching: false,
    isManualQuoteSubmitSuccess: false,
    isManualQuoteSubmitError: false,
    manualQuoteSubmitErrorMessage: '',
  },
  reducers: {
    clearQuotesState: (state) => {
      state.isQuotesError = false;
      state.isQuotesSuccess = false;
      state.isQuotesFetching = false;
      state.quotesList = [];
      return state;
    },
    clearSubmitQuoteState: (state) => {
      state.isSubmitQuoteError = false;
      state.isSubmitQuoteFetching = false;
      state.isSubmitQuoteSuccess = false;
      state.submitQuoteErrorMessage = "";
      return state;
    },
    clearManualQuoteSubmitState: (state) => {
      state.isManualQuoteSubmitError = false;
      state.isManualQuoteSubmitFetching = false;
      state.isManualQuoteSubmitSuccess = false;
      state.manualQuoteSubmitErrorMessage = "";
      return state;
    }
  },
  extraReducers: {
    [getQuotes.fulfilled]: (state, { payload }) => {
      state.isQuotesFetching = false;
      state.quotesList = payload;
      state.isQuotesSuccess = true;
      return state;
    },
    [getQuotes.rejected]: (state, { payload }) => {
      state.isQuotesFetching = false;
      state.isQuotesError = true;
      state.quotesErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [getQuotes.pending]: (state) => {
      state.isQuotesFetching = true;
    },
    [submitQuote.fulfilled]: (state, { payload }) => {
      state.isSubmitQuoteFetching = false;
      state.isSubmitQuoteSuccess = true;
      return state;
    },
    [submitQuote.rejected]: (state, { payload }) => {
      state.isSubmitQuoteFetching = false;
      state.isSubmitQuoteError = true;
      state.submitQuoteErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [submitQuote.pending]: (state) => {
      state.isSubmitQuoteFetching = true;
    },
    [declineQuote.fulfilled]: (state, { payload }) => {
      state.isDeclineQuoteFetching = false;
      state.isDeclineQuoteSuccess = true;
      return state;
    },
    [declineQuote.rejected]: (state, { payload }) => {
      state.isDeclineQuoteFetching = false;
      state.isDeclineQuoteError = true;
      state.declineQuoteErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [declineQuote.pending]: (state) => {
      state.isDeclineQuoteFetching = true;
    },
    [manualQuoteSubmit.fulfilled]: (state, { payload }) => {
      state.isManualQuoteSubmitFetching = false;
      state.isManualQuoteSubmitSuccess = true;
      return state;
    },
    [manualQuoteSubmit.rejected]: (state, { payload }) => {
      state.isManualQuoteSubmitFetching = false;
      state.isManualQuoteSubmitError = true;
      state.manualQuoteSubmitErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [manualQuoteSubmit.pending]: (state) => {
      state.isManualQuoteSubmitFetching = true;
    }
  },
});

export const { clearQuotesState, clearSubmitQuoteState, clearManualQuoteSubmitState } = quotesSlice.actions;

export const quotesSelector = (state) => state.quotes;

export default quotesSlice.reducer;
