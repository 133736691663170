import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Constants from '../configs/constants';

import axios from 'axios';

export const approveQuote = createAsyncThunk(
  'quote/approve',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios.post(Constants.API_URL + 'Quotes/approveQuote', userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deleteQuote = createAsyncThunk(
  'quote/delete',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios.post(Constants.API_URL + 'Quotes/deleteQuote', userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const declineQuoteRequest = createAsyncThunk(
  'quote/declineQuoteRequest',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios.post(Constants.API_URL + 'Quotes/declineQuoteRequest', userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const quoteActionsSlice = createSlice({
  name: 'quoteActions',
  initialState: {
    isDeleteQuoteFetching: false,
    isDeleteQuoteSuccess: false,
    isDeleteQuoteError: false,
    deleteQuoteErrorMessage: '',
    isApproveQuoteFetching: false,
    isApproveQuoteSuccess: false,
    isApproveQuoteError: false,
    approveQuoteErrorMessage: '',
    isDeclineQuoteFetching: false,
    isDeclineQuoteRequestSuccess: false,
    isDeclineQuoteError: false,
    declineQuoteErrorMessage: '',
  },
  reducers: {
    clearDeleteQuoteState: (state) => {
      state.isDeleteQuoteError = false;
      state.isDeleteQuoteSuccess = false;
      state.isDeleteQuoteFetching = false;
      state.deleteQuoteErrorMessage = "";
      return state;
    },
    clearDeclineQuoteState: (state) => {
      state.isDeclineQuoteError = false;
      state.isDeclineQuoteFetching = false;
      state.isDeclineQuoteRequestSuccess = false;
      state.declineQuoteErrorMessage = "";
      return state;
    },
    clearApproveQuoteState: (state) => {
      state.isApproveQuoteError = false;
      state.isApproveQuoteFetching = false;
      state.isApproveQuoteSuccess = false;
      state.approveQuoteErrorMessage = "";
      return state;
    }
  },
  extraReducers: {
    [deleteQuote.fulfilled]: (state, { payload }) => {
      state.isDeleteQuoteFetching = false;
      state.isDeleteQuoteSuccess = true;
      return state;
    },
    [deleteQuote.rejected]: (state, { payload }) => {
      state.isDeleteQuoteFetching = false;
      state.isDeleteQuoteError = true;
      state.deleteQuoteErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [deleteQuote.pending]: (state) => {
      state.isDeleteQuoteFetching = true;
    },
    [approveQuote.fulfilled]: (state, { payload }) => {
      state.isApproveQuoteFetching = false;
      state.isApproveQuoteSuccess = true;
      return state;
    },
    [approveQuote.rejected]: (state, { payload }) => {
      state.isApproveQuoteFetching = false;
      state.isApproveQuoteError = true;
      state.approveQuoteErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [approveQuote.pending]: (state) => {
      state.isApproveQuoteFetching = true;
    },
    [declineQuoteRequest.fulfilled]: (state, { payload }) => {
      state.isDeclineQuoteFetching = false;
      state.isDeclineQuoteRequestSuccess = true;
      return state;
    },
    [declineQuoteRequest.rejected]: (state, { payload }) => {
      state.isDeclineQuoteFetching = false;
      state.isDeclineQuoteError = true;
      state.declineQuoteErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [declineQuoteRequest.pending]: (state) => {
      state.isDeclineQuoteFetching = true;
    }
  },
});

export const { clearApproveQuoteState, clearDeclineQuoteState,clearDeleteQuoteState } = quoteActionsSlice.actions;

export const quoteActionsSelector = (state) => state.quoteActions;

export default quoteActionsSlice.reducer;