import { lazy } from 'react';

const DocumentsList = lazy(() => import('./DocumentsList'));

const DocumentsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'documents',
      element: <DocumentsList />,
    },
  ],
};

export default DocumentsConfig;
