import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Constants from '../configs/constants';

import axios from 'axios';

export const signupUser = createAsyncThunk(
  'user/create',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
      let signupUrl = Constants.API_URL+'UserAuth/createUser';
      if(userData.isTrailAccount){
        signupUrl = Constants.API_URL+'UserAuth/createUserTrailAccount';
      }
        return await axios.post(signupUrl, userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const verifyToken = createAsyncThunk(
  'user/verifyToken',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'UserAuth/verifyUserToken', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const sendEmailToken = createAsyncThunk(
  'user/sendEmailToken',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'UserAuth/sendEmailVerificationCode', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const signupSlice = createSlice({
  name: 'signup',
  initialState: {
    isSignupFetching: false,
    isSignupSuccess: false,
    isSignupError: false,
    isSignupValidSuccess: false,
    signupErrorMessage: '',    
    isVerifyTokenFetching: false,
    isVerifyTokenSuccess: false,
    isVerifyTokenError: false,
    verifyTokenErrorMessage: '',
    verifyTokenPass:false,
    signupEmail:'',
    signupCompany:'',
    isSendEmailTokenFetching: false,
    isSendEmailTokenSuccess: false,
    isSendEmailTokenError: false,
    sendEmailTokenErrorMessage: '', 
  },
  reducers: {
    clearVerifyTokenState: (state) => {
      state.isVerifyTokenError = false;
      state.isVerifyTokenSuccess = false;
      state.isVerifyTokenFetching = false;
      state.verifyTokenPass = false;
      return state;
    },
    clearSendEmailTokenState: (state) => {
      state.isSendEmailTokenFetching = false;
      state.isSendEmailTokenSuccess = false;
      state.isSendEmailTokenError = false;
      state.sendEmailTokenErrorMessage = "";
      return state;
    },
    clearSignupState: (state) => {
      state.isSignupError = false;
      state.isSignupSuccess = false;
      state.isSignupFetching = false;
      state.isSignupValidSuccess = false;
      return state;
    },
  },
  extraReducers: {
    [verifyToken.fulfilled]: (state, { payload }) => {
      state.isVerifyTokenFetching = false;
      state.isVerifyTokenSuccess = true;
      if(payload.status && payload.status == 'error'){
        state.verifyTokenPass = false;
        state.verifyTokenErrorMessage = payload.error;
      }
      else{
        state.verifyTokenPass = true;
        state.signupEmail = payload.data && payload.data.email ? payload.data.email : "";
        state.signupCompany = payload.data && payload.data.company_name ? payload.data.company_name : "";
      }
    },
    [verifyToken.pending]: (state) => {
      state.isVerifyTokenFetching = true;
    },
    [verifyToken.rejected]: (state, { payload }) => {
      state.isVerifyTokenFetching = false;
      state.isVerifyTokenError = true;
      state.verifyTokenErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [signupUser.fulfilled]: (state, { payload }) => {
      state.isSignupFetching = false;
      state.isSignupSuccess = true;
      if(payload.status && payload.status == 'error'){
        state.signupErrorMessage = payload.error;
      }
      else if(payload.status && payload.status == 'success'){
        state.isSignupValidSuccess = true;
      }
      return state;
    },
    [signupUser.rejected]: (state, { payload }) => {
      state.isSignupFetching = false;
      state.isSignupError = true;
      state.signupErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [signupUser.pending]: (state) => {
      state.isSignupFetching = true;
    }, 
    [sendEmailToken.fulfilled]: (state, { payload }) => {
      state.isSendEmailTokenFetching = false;
      if(payload.status && payload.status == 'error'){
        state.sendEmailTokenErrorMessage = payload.error;
        state.isSendEmailTokenError = true;
      }
      else if(payload.status && payload.status == 'success'){
        state.isSendEmailTokenValidSuccess = true;
      }
      return state;
    },
    [sendEmailToken.rejected]: (state, { payload }) => {
      state.isSendEmailTokenFetching = false;
      state.isSendEmailTokenError = true;
      state.sendEmailTokenMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [sendEmailToken.pending]: (state) => {
      state.isSendEmailTokenFetching = true;
    },         
  },
});

export const { clearSignupState, clearVerifyTokenState, clearSendEmailTokenState } = signupSlice.actions;

export const signupSelector = (state) => state.signup;
export default signupSlice.reducer;