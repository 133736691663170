import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Constants from "../configs/constants";

import axios from "axios";
import { isJson } from "../main/common/Helpers";

export const createUpdates = createAsyncThunk(
  "updates/create",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Users/createUpdatesAccount", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deleteUpdates = createAsyncThunk(
  "updates/delete",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Users/deleteUpdatesAccount", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getUpdates = createAsyncThunk(
  "updates/list",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Users/getUpdatesMembers", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getJobUpdates = createAsyncThunk(
  "jobUpdates/list",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Updates/getJobUpdates", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getQuoteUpdates = createAsyncThunk(
  "quoteUpdates/list",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Updates/getQuoteUpdates", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getDashboardUpdates = createAsyncThunk(
    "dashboardUpdates/list",
    async (data, thunkAPI) => {
      try {
        const userData = data;
        return await axios
          .post(Constants.API_URL + "Updates/getDashboardUpdates", userData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
            },
          })
          .then(function (response) {
            if (response.status === 200) {
              return response.data;
            } else {
              return thunkAPI.rejectWithValue(response);
            }
          });
      } catch (e) {
        console.log("Error", e.response.data);
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

export const updatesSlice = createSlice({
  name: "updates",
  initialState: {
    isUpdatesFetching: false,
    isUpdatesSuccess: false,
    isUpdatesError: false,
    updatesErrorMessage: "",
    isJobUpdatesFetching: false,
    isJobUpdatesSuccess: false,
    isJobUpdatesError: false,
    jobUpdatesErrorMessage: "",
    isDashboardUpdatesFetching: false,
    isDashboardUpdatesSuccess: false,
    isDashboardUpdatesError: false,
    dashboardUpdatesErrorMessage: "",
    isQuoteUpdatesFetching: false,
    isQuoteUpdatesSuccess: false,
    isQuoteUpdatesError: false,
    quoteUpdatesErrorMessage: "",
    isCreateUpdatesFetching: false,
    isCreateUpdatesSuccess: false,
    isCreateUpdatesError: false,
    createUpdatesErrorMessage: "",
    isDeleteUpdatesFetching: false,
    isDeleteUpdatesSuccess: false,
    isDeleteUpdatesError: false,
    deleteUpdatesErrorMessage: "",
    updatesList: [],
    dashboardUpdatesList: [],
    jobUpdatesList: [],
    quoteUpdatesList: [],
    updatesId: "",
  },
  reducers: {
    clearCreateUpdatesState: (state) => {
      state.isCreateUpdatesError = false;
      state.isCreateUpdatesSuccess = false;
      state.isCreateUpdatesFetching = false;
      state.createUpdatesErrorMessage = "";
      return state;
    },
    clearDeleteUpdatesState: (state) => {
      state.isDeleteUpdatesError = false;
      state.isDeleteUpdatesSuccess = false;
      state.isDeleteUpdatesFetching = false;
      state.deleteUpdatesErrorMessage = "";
      return state;
    },
    clearUpdatesState: (state) => {
      state.isUpdatesError = false;
      state.isUpdatesSuccess = false;
      state.isUpdatesFetching = false;
      state.updatesList = [];
      return state;
    },
    clearJobUpdatesState: (state) => {
      state.isJobUpdatesError = false;
      state.isJobUpdatesSuccess = false;
      state.isJobUpdatesFetching = false;
      state.jobUpdatesList = [];
      return state;
    },
    clearDashboardUpdatesState: (state) => {
        state.isDashboardUpdatesError = false;
        state.isDashboardUpdatesSuccess = false;
        state.isDashboardUpdatesFetching = false;
        state.dashboardUpdatesList = [];
        return state;
      },
    clearQuoteUpdatesState: (state) => {
      state.isQuoteUpdatesError = false;
      state.isQuoteUpdatesSuccess = false;
      state.isQuoteUpdatesFetching = false;
      state.quoteUpdatesList = [];
      return state;
    },

    updateUpdatesDetailId: (state, payload) => {
      if (payload.payload.updatesId) {
        state.updatesId = payload.payload.updatesId;
      }
    },
  },
  extraReducers: {
    [createUpdates.fulfilled]: (state, { payload }) => {
      state.isCreateUpdatesFetching = false;
      if (payload.status == "error") {
        state.isCreateUpdatesError = true;
        state.createUpdatesErrorMessage =
          payload.error || "Unable to add updates member. Please try again.";
      } else {
        state.isCreateUpdatesSuccess = true;
      }
    },
    [createUpdates.pending]: (state) => {
      state.isCreateUpdatesFetching = true;
    },
    [createUpdates.rejected]: (state, { payload }) => {
      state.isCreateUpdatesFetching = false;
      state.isCreateUpdatesError = true;
      state.createAccountErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [deleteUpdates.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.isDeleteUpdatesFetching = false;
      if (payload.status == "error") {
        state.isDeleteUpdatesError = true;
        state.deleteUpdatesErrorMessage =
          payload.error || "Unable to add updates member. Please try again.";
      } else {
        state.isDeleteUpdatesSuccess = true;
      }
    },
    [deleteUpdates.pending]: (state) => {
      state.isDeleteUpdatesFetching = true;
    },
    [deleteUpdates.rejected]: (state, { payload }) => {
      state.isDeleteUpdatesFetching = false;
      state.isDeleteUpdatesError = true;
      state.deleteUpdatesErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getUpdates.fulfilled]: (state, { payload }) => {
      state.isUpdatesFetching = false;
      state.updatesList = payload;
      state.isUpdatesSuccess = true;
      return state;
    },
    [getUpdates.rejected]: (state, { payload }) => {
      state.isUpdatesFetching = false;
      state.isUpdatesError = true;
      state.updatesErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getUpdates.pending]: (state) => {
      state.isUpdatesFetching = true;
    },
    [getJobUpdates.fulfilled]: (state, { payload }) => {
      state.isJobUpdatesFetching = false;
      state.jobUpdatesList = payload;
      state.isJobUpdatesSuccess = true;
      return state;
    },
    [getJobUpdates.rejected]: (state, { payload }) => {
      state.isJobUpdatesFetching = false;
      state.isJobUpdatesError = true;
      state.jobUpdatesErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getJobUpdates.pending]: (state) => {
      state.isJobUpdatesFetching = true;
    },
    [getDashboardUpdates.fulfilled]: (state, { payload }) => {
        if(isJson(payload) || Array.isArray(payload)){
            state.isDashboardUpdatesFetching = false;
            state.dashboardUpdatesList = payload;
            state.isDashboardUpdatesSuccess = true;
        }
        else{
            state.isDashboardUpdatesFetching = false;
            state.isDashboardUpdatesError = true;
            state.dashboardUpdatesErrorMessage = "Failed to load Updates. Please try again!";
        }        
        return state;
      },
      [getDashboardUpdates.rejected]: (state, { payload }) => {
        state.isDashboardUpdatesFetching = false;
        state.isDashboardUpdatesError = true;
        state.dashboardUpdatesErrorMessage =
          payload && payload.error_description
            ? payload.error_description
            : "Failed to load Updates. Please try again!";
      },
      [getJobUpdates.pending]: (state) => {
        state.isJobUpdatesFetching = true;
      },
    [getQuoteUpdates.fulfilled]: (state, { payload }) => {
      state.isQuoteUpdatesFetching = false;
      state.quoteUpdatesList = payload;
      state.isQuoteUpdatesSuccess = true;
      return state;
    },
    [getQuoteUpdates.rejected]: (state, { payload }) => {
      state.isQuoteUpdatesFetching = false;
      state.isQuoteUpdatesError = true;
      state.quoteUpdatesErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getQuoteUpdates.pending]: (state) => {
      state.isQuoteUpdatesFetching = true;
    },
  },
});

export const {
  clearUpdatesState,
  clearCreateUpdatesState,
  updateUpdatesDetailId,
  clearDeleteUpdatesState,
  clearJobUpdatesState,
  clearQuoteUpdatesState,
  clearDashboardUpdatesState
} = updatesSlice.actions;

export const updatesSelector = (state) => state.updates;
export const jobUpdatesSelector = (state) => state.updates && state.updates.jobUpdatesList || {};
export const quoteUpdatesSelector = (state) => state.updates && state.updates.quoteUpdatesList || {};
export const dashboardUpdatesSelector = (state) => state.updates && state.updates.dashboardUpdatesList || {};

export default updatesSlice.reducer;
