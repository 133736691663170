import { lazy } from 'react';

const JobDetails = lazy(() => import('./JobDetails'));

const JobConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'job/:jobId/*',
      element: <JobDetails />,
    },
  ],
};

export default JobConfig;
