import { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { selectUser } from 'app/store/userSlice';
import IconButton from '@mui/material/IconButton';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import history from '@history';
import * as Constants from '../../configs/constants';
import jwtService from 'src/app/auth/services/jwtService';
import ProfilePage from 'src/app/main/user/ProfilePage';

const Root = styled('div')(({ theme }) => ({
  '& .username, & .email': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },

  '& .avatar': {
    background: theme.palette.background.default,
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    bottom: 0,
    '& > img': {
      borderRadius: '50%',
    },
  },
}));

function UserNavbarHeader(props) {
  const user = useSelector(selectUser);
  const [moreMenuEl, setMoreMenuEl] = useState(null);
  const [openProfilePage, setOpenProfilePage] = useState(false);

  function handleMoreMenuClick(event) {
    setMoreMenuEl(event.currentTarget);
  }

  function handleMoreMenuClose(event) {
    setMoreMenuEl(null);
  }
  function handleLogout(){
    localStorage.removeItem('jwt_access_token');
    jwtService.logout();
    history.push({
      pathname: '/sign-in',
    });
  }

  const handleProfilePageClose = () => {
    setOpenProfilePage(false);
  }

  const handleProfileLinkClick = () => {
    handleMoreMenuClose();
    setOpenProfilePage(true);
  }

  return (
    <Root className="user relative flex flex-col items-left justify-center p-16 pb-14 shadow-0 user-border-bottom">
      <ProfilePage open={openProfilePage} handleProfilePageClose={handleProfilePageClose} />
      <div className="flex items-left justify-left">
        <Avatar
          sx={{
            backgroundColor: 'background.paper',
            color: 'text.secondary',
          }}
          className="avatar text-32 font-bold w-64 h-64"
          src={user.data.photoURL ? Constants.API_URL.replace("index.php/","")+"profile_images/"+user.data.photoURL : ""}
          alt={user.data.displayName}
        >
          {user.data.displayName.charAt(0)}
        </Avatar>
        <div className='user-profile-content'>
          <Typography className="username text-14 whitespace-nowrap font-medium truncate">
          {user.data.displayName}
          </Typography>
          <Typography className="account-name text-13 whitespace-nowrap font-medium truncate" color="text.secondary">
            {user.data.account_name}
          </Typography>
          <IconButton
            aria-owns={moreMenuEl ? 'main-more-menu' : null}
            aria-haspopup="true"
            onClick={handleMoreMenuClick}
            size="large"
            aria-expanded={open ? 'true' : undefined}
            // onClick={handleClick}
            className="user-profile-dots"
          >
            <FuseSvgIcon>heroicons-outline:dots-vertical</FuseSvgIcon>
          </IconButton>
          <Menu
            id="profile-sidebar-menu"
            anchorEl={moreMenuEl}
            open={Boolean(moreMenuEl)}
            onClose={handleMoreMenuClose}
          >
            <MenuItem
              onClick={() => {                
                handleProfileLinkClick();
              }}
            >
              Profile
            </MenuItem>
            <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
          </Menu>
        </div>
      </div>
    </Root>
  );
}

export default UserNavbarHeader;
