import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Constants from '../configs/constants';

import axios from 'axios';

export const createConversation = createAsyncThunk(
  'conversation/createConversation',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
      let createConversationURL = Constants.API_URL+'Conversations/createConversation';
      if(userData && userData['quoteId']){
        createConversationURL = Constants.API_URL+'Conversations/createQuoteConversation';
      }
        return await axios.post(createConversationURL, userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getConversations = createAsyncThunk(
  'conversations/list',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
      let getConversationsURL = Constants.API_URL+'Conversations/getConversations';
      if(userData && userData['quoteId']){
        getConversationsURL = Constants.API_URL+'Conversations/getQuoteConversations';
      }
        return await axios.post(getConversationsURL, userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const conversationsSlice = createSlice({
  name: 'conversations',
  initialState: {
    isConversationsFetching: false,
    isConversationsSuccess: false,
    isConversationsError: false,
    conversationsErrorMessage: '',
    isCreateConversationFetching: false,
    isCreateConversationSuccess: false,
    isCreateConversationError: false,
    createConversationErrorMessage: '',
    conversationsList:[],
    conversationId:"",
    conversationName:""
  },
  reducers: {
    clearCreateConversationState: (state) => {
      state.isCreateConversationError = false;
      state.isCreateConversationSuccess = false;
      state.isCreateConversationFetching = false;
      state.conversationId="";
      state.conversationName="";
      return state;
    },
    clearConversationsState: (state) => {
      state.isConversationsError = false;
      state.isConversationsSuccess = false;
      state.isConversationsFetching = false;
      state.conversationsList=[];
      return state;
    },
    updateConversationDetailId:(state, payload) =>{
      if(payload.payload.conversationId){
        state.conversationId = payload.payload.conversationId;
      }
      if(payload.payload.conversationName){
        state.conversationName = payload.payload.conversationName;
      }
    }
  },
  extraReducers: {
    [createConversation.fulfilled]: (state, { payload }) => {
      // console.log('payload', payload);
      state.isCreateConversationFetching = false;
      state.isCreateConversationSuccess = true;
    },
    [createConversation.pending]: (state) => {
      state.isCreateConversationFetching = true;
    },
    [createConversation.rejected]: (state, { payload }) => {
      state.isCreateConversationFetching = false;
      state.isCreateConversationError = true;
      state.createConversationErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [getConversations.fulfilled]: (state, { payload }) => {
      state.isConversationsFetching = false;
      state.conversationsList = payload;
      state.isConversationsSuccess = true;
      return state;
    },
    [getConversations.rejected]: (state, { payload }) => {
      state.isConversationsFetching = false;
      state.isConversationsError = true;
      state.conversationsErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [getConversations.pending]: (state) => {
      state.isConversationsFetching = true;
    },    
  },
});

export const { clearConversationsState, clearCreateConversationState, updateConversationDetailId } = conversationsSlice.actions;

export const conversationsSelector = (state) => state.conversations;

export default conversationsSlice.reducer;