import { combineReducers } from '@reduxjs/toolkit';
import fuse from './fuse';
import i18n from './i18nSlice';
import user from './userSlice';
import jobs from './jobsSlice';
import documents from './documentsSlice';
import jobDetail from './jobDetailSlice';
import quotes from './quotesSlice';
import quoteActions from './quoteActionsSlice';
import partners from './partnersSlice';
import conversations from './conversationsSlice';
import attachments from './attachmentsSlice';
import proposals from './proposalsSlice';
import organization from './organizationSlice';
import profile from './profileSlice';
import signup from './signupSlice';
import workTypes from './workTypesSlice';
import updates from './updatesSlice';
import invoices from './invoicesSlice';
import contacts from './contactsSlice';

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    fuse,
    i18n,
    user,
    jobs,
    jobDetail,
    quotes,
    partners,
    documents,
    quoteActions,
    conversations,
    attachments,
    organization,
    signup,
    profile,
    proposals,
    workTypes,
    updates,
    invoices,
    contacts,
    ...asyncReducers,
  });

  /*
	Reset the redux store when user logged out
	 */
  if (action.type === 'user/userLoggedOut') {
    state.user = undefined;
    state.jobs = undefined;
    state.jobDetail = undefined;
    state.quotes = undefined;
    state.partners = undefined;
    state.documents = undefined;
    state.conversations = undefined;
    state.fuse = undefined;
    state.organization = undefined;
    state.proposals = undefined;
    state.profile = undefined;
    state.workTypes = undefined;
    state.updates = undefined;
    state.contacts = undefined;
    state.invoices = undefined;
  }

  return combinedReducer(state, action);
};

export default createReducer;
