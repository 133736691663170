/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import history from "@history";
import _ from "@lodash";
import { setInitialSettings } from "app/store/fuse/settingsSlice";
import { showMessage } from "app/store/fuse/messageSlice";
import settingsConfig from "app/configs/settingsConfig";
import jwtService from "../auth/services/jwtService";
import * as Constants from '../configs/constants';
import axios from "axios";


export const setUser = createAsyncThunk(
  "user/setUser",
  async (user, { dispatch, getState }) => {
    /*
    You can redirect the logged-in user to a specific route depending on his role
    */
    if (user.loginRedirectUrl) {
      settingsConfig.loginRedirectUrl = user.loginRedirectUrl;
      // for example 'apps/academy'
    } else {
      if (user.account_type && user.account_type == "Roofing Company") {
        settingsConfig.loginRedirectUrl = "quotes";
      } else {
        settingsConfig.loginRedirectUrl = "jobs";
      }
    }
    return user;
  }
);

export const updateUserSettings = createAsyncThunk(
  "user/updateSettings",
  async (settings, { dispatch, getState }) => {
    const { user } = getState();
    const newUser = _.merge({}, user, { data: { settings } });

    dispatch(updateUserData(newUser));

    return newUser;
  }
);

export const updateUserShortcuts = createAsyncThunk(
  "user/updateShortucts",
  async (shortcuts, { dispatch, getState }) => {
    const { user } = getState();
    const newUser = {
      ...user,
      data: {
        ...user.data,
        shortcuts,
      },
    };

    dispatch(updateUserData(newUser));

    return newUser;
  }
);

export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState();

  if (!user.role || user.role.length === 0) {
    // is guest
    return null;
  }

  history.push({
    pathname: "sign-in",
  });

  dispatch(setInitialSettings());

  return dispatch(userLoggedOut());
};

export const updateUserData = (user) => async (dispatch, getState) => {
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }

  jwtService
    .updateUserData(user)
    .then(() => {
      dispatch(showMessage({ message: "User data saved with api" }));
    })
    .catch((error) => {
      dispatch(showMessage({ message: error.message }));
    });
};

export const sendForgotCode = createAsyncThunk(
  "users/sendForgotCode",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "UserAuth/sendForgotCode", userData, {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const verifyForgotCode = createAsyncThunk(
  "users/verifyForgotCode",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "UserAuth/verifyResetCode", userData, {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "users/resetPassword",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "UserAuth/resetPassword", userData, {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const initialState = {
  role: [], // guest
  data: {
    displayName: "John Doe",
    photoURL: "assets/images/avatars/brian-hughes.jpg",
    email: "johndoe@withinpixels.com",
    shortcuts: ["apps.calendar", "apps.mailbox", "apps.contacts", "apps.tasks"],
  },
};

const userSlice = createSlice({
  name: "user",
  initialState: {
    isSendForgotCodeFetching: false,
    isSendForgotCodeSuccess: false,
    isSendForgotCodeError: false,
    sendForgotCodeErrorMessage: "",
    isResetPasswordFetching: false,
    isResetPasswordSuccess: false,
    isResetPasswordError: false,
    resetPasswordErrorMessage: "",
    isVerifyForgotCodeFetching: false,
    isVerifyForgotCodeSuccess: false,
    isVerifyForgotCodeError: false,
    verifyForgotCodeErrorMessage: "",
  },
  reducers: {
    userLoggedOut: (state, action) => initialState,
    clearSendForgotCodeState: (state) => {
      state.isSendForgotCodeError = false;
      state.isSendForgotCodeSuccess = false;
      state.isSendForgotCodeFetching = false;
      state.sendForgotCodeErrorMessage = "";
      return state;
    },
    clearResetPasswordState: (state) => {
      state.isResetPasswordError = false;
      state.isResetPasswordFetching = false;
      state.isResetPasswordSuccess = false;
      state.resetPasswordErrorMessage = "";
      return state;
    },
    clearVerifyForgotCodeState: (state) => {
      state.isVerifyForgotCodeError = false;
      state.isVerifyForgotCodeFetching = false;
      state.isVerifyForgotCodeSuccess = false;
      state.verifyForgotCodeErrorMessage = "";
      return state;
    },
  },
  extraReducers: {
    [updateUserSettings.fulfilled]: (state, action) => action.payload,
    [updateUserShortcuts.fulfilled]: (state, action) => action.payload,
    [setUser.fulfilled]: (state, action) => action.payload,
    [sendForgotCode.fulfilled]: (state, { payload }) => {
      state.isSendForgotCodeFetching = false;
      if (payload.status == "error") {
        state.isSendForgotCodeError = true;
        state.sendForgotCodeErrorMessage = payload.error;
      } else {
        state.isSendForgotCodeSuccess = true;
      }
      return state;
    },
    [sendForgotCode.rejected]: (state, { payload }) => {
      state.isSendForgotCodeFetching = false;
      state.isSendForgotCodeError = true;
      state.sendForgotCodeErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [sendForgotCode.pending]: (state) => {
      state.isSendForgotCodeFetching = true;
    },
    [resetPassword.fulfilled]: (state, { payload }) => {
      state.isResetPasswordFetching = false;
      if (payload.status == "error") {
        state.isResetPasswordError = true;
        state.resetPasswordErrorMessage = payload.error;
      } else {
        state.isResetPasswordSuccess = true;
      }
      return state;
    },
    [resetPassword.rejected]: (state, { payload }) => {
      state.isResetPasswordFetching = false;
      state.isResetPasswordError = true;
      state.resetPasswordErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [resetPassword.pending]: (state) => {
      state.isResetPasswordFetching = true;
    },
    [verifyForgotCode.fulfilled]: (state, { payload }) => {
      state.isVerifyForgotCodeFetching = false;
      if (payload.status == "error") {
        state.isVerifyForgotCodeError = true;
        state.verifyForgotCodeErrorMessage = payload.error;
      } else {
        state.isVerifyForgotCodeSuccess = true;
      }
      return state;
    },
    [verifyForgotCode.rejected]: (state, { payload }) => {
      state.isVerifyForgotCodeFetching = false;
      state.isVerifyForgotCodeError = true;
      state.verifyForgotCodeErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [verifyForgotCode.pending]: (state) => {
      state.isVerifyForgotCodeFetching = true;
    },
  },
});

export const { userLoggedOut,  clearSendForgotCodeState, clearVerifyForgotCodeState, clearResetPasswordState } = userSlice.actions;

export const selectUser = ({ user }) => user;

export const selectUserShortcuts = ({ user }) => user.data.shortcuts;

export default userSlice.reducer;
