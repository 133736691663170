import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as Constants from "../configs/constants";

import axios from "axios";

export const createWorkType = createAsyncThunk(
  "workType/create",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "WorkTypes/createWorkType", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token")
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const createRoofingWorkType = createAsyncThunk(
    "roofingWorkType/create",
    async (data, thunkAPI) => {
      try {
        const userData = data;
        return await axios
          .post(Constants.API_URL + "WorkTypes/createRoofingWorkType", userData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwt_access_token")
            },
          })
          .then(function (response) {
            if (response.status === 200) {
              return response.data;
            } else {
              return thunkAPI.rejectWithValue(response);
            }
          });
      } catch (e) {
        console.log("Error", e.response.data);
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

export const getWorkTypes = createAsyncThunk(
  "workTypes/list",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "WorkTypes/getWorkTypesList", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getRoofingWorkTypes = createAsyncThunk(
  "roofingWorkTypes/list",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(
          Constants.API_URL + "WorkTypes/getRoofingWorkTypesList",
          userData,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateWorkType = createAsyncThunk(
  "workType/updateWorkType",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Users/updateWorkType", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deleteWorkType = createAsyncThunk(
  "workType/delete",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "WorkTypes/deleteWorkType", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const workTypesSlice = createSlice({
  name: "workTypes",
  initialState: {
    isWorkTypesFetching: false,
    isWorkTypesSuccess: false,
    isWorkTypesError: false,
    workTypesErrorMessage: "",
    isRoofingWorkTypesFetching: false,
    isRoofingWorkTypesSuccess: false,
    isRoofingWorkTypesError: false,
    roofingWorkTypesErrorMessage: "",
    isCreateWorkTypeFetching: false,
    isCreateWorkTypeSuccess: false,
    isCreateWorkTypeError: false,
    createWorkTypeErrorMessage: "",
    isCreateRoofingWorkTypeFetching: false,
    isCreateRoofingWorkTypeSuccess: false,
    isCreateRoofingWorkTypeError: false,
    createRoofingWorkTypeErrorMessage: "",
    workTypesList: [],
    roofingWorkTypesList: [],
    workTypeId: "",
    workTypeName: "",
    isUpdateWorkTypeFetching: false,
    isUpdateWorkTypeSuccess: false,
    isUpdateWorkTypeError: false,
    updateWorkTypeErrorMessage: "",
    isDeleteWorkTypeFetching: false,
    isDeleteWorkTypeSuccess: false,
    isDeleteWorkTypeError: false,
    deleteWorkTypeErrorMessage: "",
  },
  reducers: {
    clearCreateWorkTypeState: (state) => {
      state.isCreateWorkTypeError = false;
      state.isCreateWorkTypeSuccess = false;
      state.isCreateWorkTypeFetching = false;
      return state;
    },
    clearCreateRoofingWorkTypeState: (state) => {
        state.isCreateRoofingWorkTypeError = false;
        state.isCreateRoofingWorkTypeSuccess = false;
        state.isCreateRoofingWorkTypeFetching = false;
        return state;
      },
    clearUpdateWorkTypeState: (state) => {
      state.isUpdateWorkTypeError = false;
      state.isUpdateWorkTypeSuccess = false;
      state.isUpdateWorkTypeFetching = false;
      state.updateWorkTypeErrorMessage = "";
      return state;
    },
    clearDeleteWorkTypeState: (state) => {
      state.isDeleteWorkTypeError = false;
      state.isDeleteWorkTypeSuccess = false;
      state.isDeleteWorkTypeFetching = false;
      state.deleteWorkTypeErrorMessage = "";
      return state;
    },
    clearWorkTypesState: (state) => {
      state.isWorkTypesError = false;
      state.isWorkTypesSuccess = false;
      state.isWorkTypesFetching = false;
      state.workTypesList = [];
      return state;
    },
    clearRoofingWorkTypesState: (state) => {
      state.isRoofingWorkTypesError = false;
      state.isRoofingWorkTypesSuccess = false;
      state.isRoofingWorkTypesFetching = false;
      state.roofingWorkTypesList = [];
      return state;
    },
    updateWorkTypeDetailId: (state, payload) => {
      if (payload.payload.workTypeId) {
        state.workTypeId = payload.payload.workTypeId;
      }
      if (payload.payload.workTypeName) {
        state.workTypeName = payload.payload.workTypeName;
      }
    },
    updateWorkTypesList: (state, payload) => {
      state.workTypesList = payload.payload;
    },
  },
  extraReducers: {
    [createWorkType.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.isCreateWorkTypeFetching = false;
      if (payload.status == "error") {
        state.isCreateWorkTypeError = true;
        state.createWorkTypeErrorMessage =
          payload.error || "Invalid file. Please try agian.";
      } else {
        state.isCreateWorkTypeSuccess = true;
      }
    },
    [createWorkType.pending]: (state) => {
      state.isCreateWorkTypeFetching = true;
    },
    [createWorkType.rejected]: (state, { payload }) => {
      state.isCreateWorkTypeFetching = false;
      state.isCreateWorkTypeError = true;
      state.createWorkTypeErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [createRoofingWorkType.fulfilled]: (state, { payload }) => {
        console.log("payload", payload);
        state.isCreateRoofingWorkTypeFetching = false;
        if (payload.status == "error") {
          state.isCreateRoofingWorkTypeError = true;
          state.createRoofingWorkTypeErrorMessage =
            payload.error || "Invalid file. Please try agian.";
        } else {
          state.isCreateRoofingWorkTypeSuccess = true;
        }
      },
      [createRoofingWorkType.pending]: (state) => {
        state.isCreateRoofingWorkTypeFetching = true;
      },
      [createRoofingWorkType.rejected]: (state, { payload }) => {
        state.isCreateRoofingWorkTypeFetching = false;
        state.isCreateRoofingWorkTypeError = true;
        state.createRoofingWorkTypeErrorMessage =
          payload && payload.error_description
            ? payload.error_description
            : "Invalid Credentials. Please try again!";
      },
    [getWorkTypes.fulfilled]: (state, { payload }) => {
      state.isWorkTypesFetching = false;
      state.workTypesList = payload;
      state.isWorkTypesSuccess = true;
      return state;
    },
    [getWorkTypes.rejected]: (state, { payload }) => {
      state.isWorkTypesFetching = false;
      state.isWorkTypesError = true;
      state.workTypesErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getWorkTypes.pending]: (state) => {
      state.isWorkTypesFetching = true;
    },
    [getRoofingWorkTypes.fulfilled]: (state, { payload }) => {
      state.isRoofingWorkTypesFetching = false;
      state.roofingWorkTypesList = payload;
      state.isRoofingWorkTypesSuccess = true;
      return state;
    },
    [getRoofingWorkTypes.rejected]: (state, { payload }) => {
      state.isRoofingWorkTypesFetching = false;
      state.isRoofingWorkTypesError = true;
      state.roofingWorkTypesErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getRoofingWorkTypes.pending]: (state) => {
      state.isRoofingWorkTypesFetching = true;
    },
    [updateWorkType.fulfilled]: (state, { payload }) => {
      state.isUpdateWorkTypeFetching = false;
      state.isUpdateWorkTypeSuccess = true;
      return state;
    },
    [updateWorkType.rejected]: (state, { payload }) => {
      state.isUpdateWorkTypeFetching = false;
      state.isUpdateWorkTypeError = true;
      state.updateWorkTypeErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [updateWorkType.pending]: (state) => {
      state.isUpdateWorkTypeFetching = true;
    },
    [deleteWorkType.fulfilled]: (state, { payload }) => {
      state.isDeleteWorkTypeFetching = false;
      state.isDeleteWorkTypeSuccess = true;
      return state;
    },
    [deleteWorkType.rejected]: (state, { payload }) => {
      state.isDeleteWorkTypeFetching = false;
      state.isDeleteWorkTypeError = true;
      state.deleteWorkTypeErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [deleteWorkType.pending]: (state) => {
      state.isDeleteWorkTypeFetching = true;
    },
  },
});

export const {
  clearWorkTypesState,
  clearCreateWorkTypeState,
  updateWorkTypesList,
  updateWorkTypeDetailId,
  clearUpdateWorkTypeState,
  clearDeleteWorkTypeState,
  clearRoofingWorkTypesState,
  clearCreateRoofingWorkTypeState
} = workTypesSlice.actions;

export const workTypesSelector = (state) => state.workTypes;

export default workTypesSlice.reducer;
