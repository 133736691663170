import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Constants from '../configs/constants';

import axios from 'axios';

export const createAttachment = createAsyncThunk(
  'attachment/create',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Jobs/createAttachment', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token'),
              "Content-Type" : "multipart/form-data",
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAttachments = createAsyncThunk(
  'attachments/list',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Jobs/getAttachments', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const uploadPhoto = createAsyncThunk(
  'user/createPhoto',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Users/uploadPhoto', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token'),
              "Content-Type" : "multipart/form-data",
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deleteAttachment = createAsyncThunk(
  'attachment/delete',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Jobs/deleteAttachment', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const attachmentsSlice = createSlice({
  name: 'attachments',
  initialState: {
    isAttachmentsFetching: false,
    isAttachmentsSuccess: false,
    isAttachmentsError: false,
    attachmentsErrorMessage: '',
    isCreateAttachmentFetching: false,
    isCreateAttachmentSuccess: false,
    isCreateAttachmentError: false,
    createAttachmentErrorMessage: '',
    attachmentsList:[],
    attachmentId:"",
    attachmentName:"",
    isUploadPhotoFetching: false,
    isUploadPhotoSuccess: false,
    isUploadPhotoError: false,
    uploadPhotoErrorMessage: '',
    isDeleteAttachmentFetching: false,
    isDeleteAttachmentSuccess: false,
    isDeleteAttachmentError: false,
    deleteAttachmentErrorMessage: '',
  },
  reducers: {
    clearCreateAttachmentState: (state) => {
      state.isCreateAttachmentError = false;
      state.isCreateAttachmentSuccess = false;
      state.isCreateAttachmentFetching = false;
      return state;
    },
    clearUploadPhotoState: (state) => {
      state.isUploadPhotoError = false;
      state.isUploadPhotoSuccess = false;
      state.isUploadPhotoFetching = false;
      state.uploadPhotoErrorMessage = "";
      return state;
    },
    clearDeleteAttachmentState: (state) => {
      state.isDeleteAttachmentError = false;
      state.isDeleteAttachmentSuccess = false;
      state.isDeleteAttachmentFetching = false;
      state.deleteAttachmentErrorMessage = "";
      return state;
    },
    clearAttachmentsState: (state) => {
      state.isAttachmentsError = false;
      state.isAttachmentsSuccess = false;
      state.isAttachmentsFetching = false;
      state.attachmentsList=[];
      return state;
    },
    updateAttachmentDetailId:(state, payload) =>{
      if(payload.payload.attachmentId){
        state.attachmentId = payload.payload.attachmentId;
      }
      if(payload.payload.attachmentName){
        state.attachmentName = payload.payload.attachmentName;
      }
    },
    updateAttachmentsList:(state,payload) => {
      state.attachmentsList = payload.payload;
    }
  },
  extraReducers: {
    [createAttachment.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.isCreateAttachmentFetching = false;      
      if(payload.status == 'error'){
        state.isCreateAttachmentError = true;
        state.createAttachmentErrorMessage = payload.error || "Invalid file. Please try agian.";
      }
      else{
        state.isCreateAttachmentSuccess = true;
      }
    },
    [createAttachment.pending]: (state) => {
      state.isCreateAttachmentFetching = true;
    },
    [createAttachment.rejected]: (state, { payload }) => {
      state.isCreateAttachmentFetching = false;
      state.isCreateAttachmentError = true;
      state.createAttachmentErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [getAttachments.fulfilled]: (state, { payload }) => {
      state.isAttachmentsFetching = false;
      state.attachmentsList = payload;
      state.isAttachmentsSuccess = true;
      return state;
    },
    [getAttachments.rejected]: (state, { payload }) => {
      state.isAttachmentsFetching = false;
      state.isAttachmentsError = true;
      state.attachmentsErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [getAttachments.pending]: (state) => {
      state.isAttachmentsFetching = true;
    },
    [uploadPhoto.fulfilled]: (state, { payload }) => {
      state.isUploadPhotoFetching = false;
      state.isUploadPhotoSuccess = true;
      return state;
    },
    [uploadPhoto.rejected]: (state, { payload }) => {
      state.isUploadPhotoFetching = false;
      state.isUploadPhotoError = true;
      state.uploadPhotoErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [uploadPhoto.pending]: (state) => {
      state.isUploadPhotoFetching = true;
    },
    [deleteAttachment.fulfilled]: (state, { payload }) => {
      state.isDeleteAttachmentFetching = false;
      state.isDeleteAttachmentSuccess = true;
      return state;
    },
    [deleteAttachment.rejected]: (state, { payload }) => {
      state.isDeleteAttachmentFetching = false;
      state.isDeleteAttachmentError = true;
      state.deleteAttachmentErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [deleteAttachment.pending]: (state) => {
      state.isDeleteAttachmentFetching = true;
    },    
  },
});




export const { clearAttachmentsState, clearCreateAttachmentState, updateAttachmentsList, updateAttachmentDetailId, clearUploadPhotoState, clearDeleteAttachmentState } = attachmentsSlice.actions;

export const attachmentsSelector = (state) => state.attachments;

export default attachmentsSlice.reducer;
