import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Constants from '../configs/constants';

import axios from 'axios';

export const createPartnerInvite = createAsyncThunk(
  'partner/invite',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Accounts/createPartnerAccount', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getPartnerInvites = createAsyncThunk(
  'invites/list',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Partners/getPartners', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      if(e && e.response){
        console.log('Error', e.response.data);
        return thunkAPI.rejectWithValue(e.response.data);
      }
      else{
        console.log('Error', e.message);
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  }
);

export const getPartnerInvitesByJob = createAsyncThunk(
  'partners/list',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Partners/getPartnersByJobId', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      if(e && e.response){
        console.log('Error', e.response.data);
        return thunkAPI.rejectWithValue(e.response.data);
      }
      else{
        console.log('Error', e.message);
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  }
);

export const requestAQuote = createAsyncThunk(
  'request/quote',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Quotes/requestAQuote', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deletePartnerInvite = createAsyncThunk(
  'partner/deleteInvite',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Partners/deleteInvite', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);


export const partnersSlice = createSlice({
  name: 'partners',
  initialState: {
    isPartnersListFetching: false,    
    isPartnersListSuccess: false,    
    isPartnersListError: false,    
    partnersListErrorMessage: '',    
    partnersList:[],
    isJobPartnersListFetching: false,    
    isJobPartnersListSuccess: false,    
    isJobPartnersListError: false,    
    jobPartnersListErrorMessage: '',    
    jobPartnersList:[],
    isInvitePartnerFetching: false,
    isInvitePartnerSuccess: false,
    isInvitePartnerError: false,
    invitePartnerErrorMessage: '',
    isRequestAQuoteFetching: false,
    isRequestAQuoteSuccess: false,
    isRequestAQuoteError: false,
    requestAQuoteErrorMessage: '',
    isDeletePartnerInviteFetching: false,
    isDeletePartnerInviteSuccess: false,
    isDeletePartnerInviteError: false,
    deletePartnerInviteErrorMessage: '',
  },
  reducers: {
    clearInvitePartnerState: (state) => {      
      state.isInvitePartnerFetching = false;
      state.isInvitePartnerSuccess = false;
      state.isInvitePartnerError = false;
      state.invitePartnerErrorMessage = "";
      return state;
    },
    clearDeletePartnerInviteState: (state) => {      
      state.isDeletePartnerInviteFetching = false;
      state.isDeletePartnerInviteSuccess = false;
      state.isDeletePartnerInviteError = false;
      state.deletePartnerInviteErrorMessage = "";
      return state;
    },
    clearPartnersListState: (state) => {
      state.isPartnersListFetching = false;
      state.isPartnersListSuccess = false;
      state.isPartnersListError = false;
      state.partnersList = [];
      return state;
    },
    clearJobPartnersListState: (state) => {
      state.isJobPartnersListFetching = false;
      state.isJobPartnersListSuccess = false;
      state.isJobPartnersListError = false;
      state.jobPartnersList = [];
      return state;
    },
    clearRequestAQuoteState: (state) => {
      state.isRequestAQuoteFetching = false;
      state.isRequestAQuoteSuccess = false;
      state.isRequestAQuoteError = false;
      return state;
    }
  },
  extraReducers: {  
    [deletePartnerInvite.fulfilled]: (state, { payload }) => {
      state.isDeletePartnerInviteFetching = false;
      state.isDeletePartnerInviteSuccess = true;
      return state;
    },
    [deletePartnerInvite.rejected]: (state, { payload }) => {
      state.isDeletePartnerInviteFetching = false;
      state.isDeletePartnerInviteError = true;
      state.deletePartnerInviteErrorMessage = payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [deletePartnerInvite.pending]: (state) => {
      state.isDeletePartnerInviteFetching = true;
    },  
    [createPartnerInvite.fulfilled]: (state, { payload }) => {
      state.isInvitePartnerFetching = false;
      state.isInvitePartnerSuccess = true;
      return state;
    },
    [createPartnerInvite.rejected]: (state, { payload }) => {
      state.isInvitePartnerFetching = false;
      state.isInvitePartnerError = true;
      state.invitePartnerErrorMessage = payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [createPartnerInvite.pending]: (state) => {
      state.isInvitePartnerFetching = true;
    },
    [getPartnerInvites.fulfilled]: (state, { payload }) => {
      state.isPartnersListFetching = false;
      state.partnersList = payload;
      state.isPartnersListSuccess = true;
      return state;
    },
    [getPartnerInvites.rejected]: (state, { payload }) => {
      state.isPartnersListFetching = false;
      state.isPartnersListError = true;
      state.partnersListErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [getPartnerInvites.pending]: (state) => {
      state.isPartnersListFetching = true;
    },
    [getPartnerInvitesByJob.fulfilled]: (state, { payload }) => {
      state.isJobPartnersListFetching = false;
      state.jobPartnersList = payload;
      state.isJobPartnersListSuccess = true;
      return state;
    },
    [getPartnerInvitesByJob.rejected]: (state, { payload }) => {
      state.isJobPartnersListFetching = false;
      state.isJobPartnersListError = true;
      state.jobPartnersListErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [getPartnerInvitesByJob.pending]: (state) => {
      state.isJobPartnersListFetching = true;
    },
    [requestAQuote.fulfilled]: (state, { payload }) => {
      state.isRequestAQuoteFetching = false;
      state.isRequestAQuoteSuccess = true;
      return state;
    },
    [requestAQuote.rejected]: (state, { payload }) => {
      state.isRequestAQuoteFetching = false;
      state.isRequestAQuoteError = true;
      state.requestAQuoteErrorMessage = payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [requestAQuote.pending]: (state) => {
      state.isRequestAQuoteFetching = true;
    },
  },
});

export const { clearInvitePartnerState, clearPartnersListState, clearRequestAQuoteState, clearJobPartnersListState, clearDeletePartnerInviteState } = partnersSlice.actions;

export const partnersSelector = (state) => state.partners;
export const partnersListSelector = (state) => state.partners && state.partners.partnersList && state.partners.partnersList.partners_list || [];
export const partnersInviteListSelector = (state) => state.partners && state.partners.partnersList && state.partners.partnersList.invite_list || [];
export const partnersInviteSelector = (state) => state.partners.jobPartnersList;
export default partnersSlice.reducer;