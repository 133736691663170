import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Constants from '../configs/constants';

import axios from 'axios';


export const createJob = createAsyncThunk(
  'jobDetail/create',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Jobs/createJob', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getQuotesByJob = createAsyncThunk(
  'quotes/listByJob',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Quotes/getQuotes', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getJobDetail = createAsyncThunk(
  'job/detail',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Jobs/getJobDetail', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getJobDetailByKey = createAsyncThunk(
  'job/detailByKey',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Jobs/getJobDetailByKey', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getJobDetailByQuoteKey = createAsyncThunk(
  'job/detailByQuoteKey',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Jobs/getJobDetailByQuoteKey', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getQuoteStatusByJob = createAsyncThunk(
  'job/quoteStatus',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Quotes/getQuoteStatusByJob', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateJob = createAsyncThunk(
  'jobDetail/update',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Jobs/updateJob', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const jobDetailSlice = createSlice({
  name: 'jobDetail',
  initialState: {
    isJobDetailFetching: false,
    isJobDetailSuccess: false,
    isJobDetailError: false,
    jobDetailErrorMessage: '',
    jobDetails:{
      log_account_type:''
    },
    jobAttachments:[],
    jobId:'',
    requestFrom:'',
    accountType:'',
    isQuoteListFetching: false,
    isQuoteListSuccess: false,
    isQuoteListError: false,
    quoteListErrorMessage: '',
    quotesListByJob:[],
    isQuoteStatusFetching: false,
    isQuoteStatusSuccess: false,
    isQuoteStatusError: false,
    quoteStatusByJob:[],
    quoteStatusErrorMessage:'',
    isUpdateJobFetching: false,
    isUpdateJobSuccess: false,
    isUpdateJobError: false,
    updateJobErrorMessage: '',
  },
  reducers: {
    clearJobDetailState: (state) => {
      state.isJobDetailFetching = false;
      state.isJobDetailSuccess = false;
      state.isJobDetailError = false;
      state.jobDetails = [];
      state.jobAttachments = [];
      state.jobId = '';
      state.requestFrom = '';
      state.accountType = '';
      return state;
    },
    clearQuotesListState: (state) => {
      state.isQuoteListFetching = false;
      state.isQuoteListSuccess = false;
      state.isQuoteListError = false;
      state.quotesListByJob = [];
      return state;
    },
    clearUpdateJobState: (state) => {
      state.isUpdateJobError = false;
      state.isUpdateJobFetching = false;
      state.isUpdateJobSuccess = false;
      state.updateJobErrorMessage = "";
      return state;
    },
    clearQuoteStatusByJobState: (state) => {
      state.isQuoteStatusFetching = false;
      state.isQuoteStatusSuccess = false;
      state.isQuoteStatusError = false;
      state.quoteStatusByJob = [];
      state.quoteStatusErrorMessage = '';
      return state;
    },
    updateJobDetailId:(state, payload) =>{
      if(payload.payload.jobId){
        state.jobId = payload.payload.jobId;
      }
      if(payload.payload.requestFrom){
        state.requestFrom = payload.payload.requestFrom;
      }
      if(payload.payload.accountType){
        state.accountType = payload.payload.accountType;
      }
    }
  },
  extraReducers: {    
    [createJob.fulfilled]: (state, { payload }) => {
      state.isJobFetching = false;
      state.isJobSuccess = true;
      return state;
    },
    [createJob.rejected]: (state, { payload }) => {
      state.isJobFetching = false;
      state.isJobError = true;
      state.jobErrorMessage = payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [createJob.pending]: (state) => {
      state.isJobFetching = true;
    },
    [updateJob.fulfilled]: (state, { payload }) => {
      state.isUpdateJobFetching = false;
      if(payload.status == "error"){
        state.updateJobErrorMessage = payload.error || "Unable to update job. Please try again.";
        state.isUpdateJobError = true;
      }
      else{
        state.isUpdateJobSuccess = true;
      }      
      return state;
    },
    [updateJob.rejected]: (state, { payload }) => {
      state.isUpdateJobFetching = false;
      state.isUpdateJobError = true;
      state.updateJobErrorMessage = payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [updateJob.pending]: (state) => {
      state.isUpdateJobFetching = true;
    },
    [getJobDetail.fulfilled]: (state, { payload }) => {
      state.isJobDetailFetching = false;
      state.jobDetails = payload;
      state.isJobDetailSuccess = true;
      return state;
    },
    [getJobDetail.rejected]: (state, { payload }) => {
      state.isJobDetailFetching = false;
      state.isJobDetailError = true;
      state.jobDetailErrorMessage = payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [getJobDetail.pending]: (state) => {
      state.isJobDetailFetching = true;
    },
    [getJobDetailByKey.fulfilled]: (state, { payload }) => {
      state.isJobDetailFetching = false;
      if(payload.status == "error"){
        state.isJobDetailError = true;
        state.jobDetailErrorMessage = payload.error ? payload.error : "Invalid Job number. Please try again";
      }
      else{
        state.jobDetails = payload.data;
        state.jobAttachments = payload && payload.attachments || [];
        state.jobId = payload.data.job_id;
        state.isJobDetailSuccess = true;
      }
      return state;
    },
    [getJobDetailByKey.rejected]: (state, { payload }) => {
      state.isJobDetailFetching = false;
      state.isJobDetailError = true;
      state.jobDetailErrorMessage = payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [getJobDetailByKey.pending]: (state) => {
      state.isJobDetailFetching = true;
    },
    [getJobDetailByQuoteKey.fulfilled]: (state, { payload }) => {
      state.isJobDetailFetching = false;
      if(payload.status == "error"){
        state.isJobDetailError = true;
        state.jobDetailErrorMessage = payload.error ? payload.error : "Invalid Job number. Please try again";
      }
      else{
        state.jobDetails = payload.data;
        state.jobId = payload.data.job_id;
        state.isJobDetailSuccess = true;
      }
      return state;
    },
    [getJobDetailByQuoteKey.rejected]: (state, { payload }) => {
      state.isJobDetailFetching = false;
      state.isJobDetailError = true;
      state.jobDetailErrorMessage = payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [getJobDetailByQuoteKey.pending]: (state) => {
      state.isJobDetailFetching = true;
    },
    [getQuotesByJob.fulfilled]: (state, { payload }) => {
      state.isQuoteListFetching = false;
      state.quotesListByJob = payload;
      state.isQuoteListSuccess = true;
      return state;
    },
    [getQuotesByJob.rejected]: (state, { payload }) => {
      state.isQuoteListFetching = false;
      state.isQuoteListError = true;
      state.quoteListErrorMessage = payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [getQuotesByJob.pending]: (state) => {
      state.isQuoteListFetching = true;
    },
    [getQuoteStatusByJob.fulfilled]: (state, { payload }) => {
      state.isQuoteStatusFetching = false;
      state.quoteStatusByJob = payload;
      state.isQuoteStatusSuccess = true;
      return state;
    },
    [getQuoteStatusByJob.rejected]: (state, { payload }) => {
      state.isQuoteStatusFetching = false;
      state.isQuoteStatusError = true;
      state.quoteStatusErrorMessage = payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [getQuoteStatusByJob.pending]: (state) => {
      state.isQuoteStatusFetching = true;
    },
  },
});

export const { clearJobDetailState, updateJobDetailId, clearQuotesListState, clearUpdateJobState } = jobDetailSlice.actions;

export const jobDetailSelector = (state) => state.jobDetail;
export const quotesListSelector = (state) => state.jobDetail && state.jobDetail.quotesListByJob || [];

export default jobDetailSlice.reducer;
